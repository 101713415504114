import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import DetailService from "../DetailService";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function NetworkInfo({ selectedThingId }) {
    const [thing, setThing] = useState();
    const [lorawan, setLorawan] = useState();
    const [devEui, setDeveui] = useState();
    const [otaaVersion, setOttaVersion] = useState();
    const [reagion, setRegion] = useState();
    const [company, setCompany] = useState();
    const [serial, setSerial] = useState();

    const [isSensingNetwork, setIsSensingNetwork] = useState(false);
    const [isMqttProduct, setIsMqttProduct] = useState(false);
    const [cellularImei, setCellularImei] = useState();

    const [iotCertificate, setIotCertificate] = useState();
    const [iotPublicKey, setIotPublicKey] = useState();
    const [iotPrivateKey, setIotPrivateKey] = useState();

    useEffect(() => {
        DetailService.requestThing(selectedThingId, setThing).then((r) => {
            // console.log(r);
            // console.log(r.customFields);

            setCompany(r.company);

            if (r && r.customFields) {
                // console.log(r.customFields);

                const findField = (name) => r.customFields.find((c) => c.name === name);

                setDeveui(findField("devEui")?.value);
                setOttaVersion(findField("OtaaV1_0") ? "v1.0.1" : findField("OtaaV1_1") ? "v1.1" : undefined);
                setRegion(findField("lorawanRegion")?.value);

                setIotCertificate(findField("IOT_CERTIFICATE_PEM")?.value);
                setIotPublicKey(findField("IOT_PUBLIC_KEY")?.value);
                setIotPrivateKey(findField("IOT_PRIVATE_KEY")?.value);
            }

            if (r.lorawanWirelessDeviceId) {
                setIsSensingNetwork(true);
            }
            if (r.product) {
                const lora = r.product.connectivityTypes.find((c) => c == "LoRaWAN");
                if (lora) {
                    setLorawan("LoRaWAN");
                }

                if (r.product.name.includes("MQTT") || r.product.connectivityTypes.includes("MQTT")) {
                    setIsMqttProduct(true);
                }
            }

            if (r.cellularImei) setCellularImei(r.cellularImei);
            if (r.serialNumber) setSerial(r.serialNumber);
        });
    }, [selectedThingId]);

    const handleSerial = (e) => {
        setSerial(e.target.value);
        DetailService.updateSerialNumber(selectedThingId, e.target.value);
    };

    const createDownloadLink = (data, filename, extension) => {
        if (!data) return null;
        const blob = new Blob([data], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        return (
            <a href={url} download={`${filename}.${extension}`}>
                {filename}.{extension}
            </a>
        );
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Paper elevation={3} sx={{ p: 2 }}>
                {(lorawan || isMqttProduct || cellularImei) && (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                                "&.Mui-expanded": { minHeight: 0 },
                                "&.MuiAccordionSummary-content": { margin: 0 },
                            }}
                        >
                            {lorawan && (
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {lorawan}
                                    </Typography>
                                </Grid>
                            )}
                            {isMqttProduct && cellularImei && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" gutterBottom>
                                            MQTT
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid container>
                                <>
                                    <Grid item xs={1}>
                                        &nbsp;
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" gutterBottom>
                                            Network server
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant="body2" gutterBottom>
                                            {isSensingNetwork ? "Device Explorer" : "Unknown"}
                                        </Typography>
                                    </Grid>
                                </>
                                {devEui && (
                                    <>
                                        <Grid item xs={1}>
                                            &nbsp;
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" gutterBottom>
                                                DEVEUI
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography variant="body2" gutterBottom>
                                                {devEui}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                {otaaVersion && isSensingNetwork && (
                                    <>
                                        <Grid item xs={1}>
                                            &nbsp;
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" gutterBottom>
                                                OTAA
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography variant="body2" gutterBottom>
                                                {otaaVersion}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                {reagion && isSensingNetwork && (
                                    <>
                                        <Grid item xs={1}>
                                            &nbsp;
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" gutterBottom>
                                                Region
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography variant="body2" gutterBottom>
                                                {reagion}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                {isMqttProduct && cellularImei && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" gutterBottom>
                                                Publish
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ pl: 5 }}>
                                            <Typography variant="body2" gutterBottom>
                                                JSON: sn/{company}/{cellularImei}/telemetry
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                RAW: sn/{company}/{cellularImei}/message
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" gutterBottom>
                                                Subscribe
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ pl: 5 }}>
                                            <Typography variant="body2" gutterBottom>
                                                sn/{company}/{cellularImei}/action
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                            "&.Mui-expanded": { minHeight: 0 },
                            "&.MuiAccordionSummary-content": { margin: 0 },
                        }}
                    >
                        <Typography variant="subtitle1" gutterBottom>
                            Device
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            {cellularImei && (
                                <>
                                    <Grid item xs={3} sx={{ display: "flex", alignItems: "center", pl: 5 }}>
                                        <Typography variant="body2" gutterBottom>
                                            IMEI
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="body2" gutterBottom>
                                            {cellularImei}
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            {iotCertificate && (
                                <>
                                    <Grid item xs={3} sx={{ display: "flex", alignItems: "center", pl: 5 }}>
                                        <Typography variant="body2" gutterBottom>
                                            Certificate
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, pb: 2 }}>
                                            {createDownloadLink(iotCertificate, "Certificate", "pem")}
                                            {createDownloadLink(iotPublicKey, "Public", "key")}
                                            {createDownloadLink(iotPrivateKey, "Private", "key")}
                                        </Box>
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={1}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="body2" gutterBottom>
                                    Serial
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="txtSerial"
                                    label=""
                                    variant="outlined"
                                    value={serial}
                                    size="small"
                                    onChange={(e) => handleSerial(e)}
                                    inputProps={{ maxLength: 20 }}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </Box>
    );
}
