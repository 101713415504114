import { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import AppContext from "Components/AppContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import TopToolBar from "./toolBar/TopToolBar";
import MeasurementView from "./measurements/MeasurementView";
import SettingContext from "Providers/SettingContext";
import Box from "@mui/material/Box";
import moment from "moment";
import Item from "../../components/ui/Item";
import { useTranslation } from "react-i18next";
import UserService from "Services/userService";
import NoSubscriptionDialog from "Components/subscription/NoSubscriptionDialog";
import NoSubscriptionInitiallyDialog from "Components/subscription/NoSubscriptionInitiallyDialog";

export default function DeviceExplorer() {
    const { t, i18n } = useTranslation();
    const { WATER_MANAGEMENT, SMART_AGRICULTURE, DEVICE_EXPLORER, SENS_DC, EMERA_V2, SUPERVIOT } = AppId;

    const params = useParams();
    const appId = params.appId;

    const [keyword, setKeyword] = useState("");

    let appCtx = useContext(AppContext);
    const setting = useContext(SettingContext);
    const [reload, setReload] = useState();

    const [company, setCompany] = useState();
    const [companyId, setCompanyId] = useState();

    const [noSub, setNoSub] = useState(false);
    const [expired, setExpired] = useState(false);
    const [subId, setSubId] = useState();

    useEffect(() => {
        setting.setGlobalSetting({
            appId: appId,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        switch (appId) {
            case DEVICE_EXPLORER:
                appCtx.updateTitle("Device Explorer");
                break;
            case WATER_MANAGEMENT:
            case SMART_AGRICULTURE:
            case EMERA_V2:
            case SENS_DC:
            case SUPERVIOT:
                appCtx.updateTitle(ApplicationData.find((a) => a.id == appId).title);
                break;
            default:
                break;
        }

        if (appId === DEVICE_EXPLORER) {
            UserService.getAuthenticatedUser().then((u) => {
                setCompany(u.company);
                setCompanyId(u.companyId);

                // Test & Start sites only give a 3-months subscription for the DEMO company.
                // Other companies doesn't affected the subscription
                if (u.company == "DEMO") {
                    if (u.lastSubscription && u.lastSubscription.expired) {
                        setNoSub(true);
                        setExpired(true);
                        setSubId(u.lastSubscription.id);
                    }

                    if (!u.lastSubscription) {
                        setNoSub(true);
                    }

                    // if (u.authorities == "CLIENT_ADMIN" || u.authorities == "SUPER_ADMIN") {
                    if (u.authorities == "SUPER_ADMIN") {
                        setNoSub(false);
                    }
                }
            });
        }
    }, [keyword, reload]);

    const searchKeyword = useCallback((keyword) => {
        setKeyword(keyword);
    });

    const handleRenewSubscription = () => {
        setNoSub(false);
        window.location.href = "/apps"; // Reload the page to get the subscription which was made just before
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                flexShrink: 0,
                height: "100%",
            }}
        >
            <Item sx={{ mt: "35px" }} elevation={0}>
                &nbsp;
            </Item>

            <Item sx={{ height: 90, ml: "10px", mr: "10px" }} elevation={0}>
                <TopToolBar callback={searchKeyword} appId={appId}></TopToolBar>
            </Item>

            <Item sx={{ height: "100%", m: "10px", pb: "20px" }}>
                <MeasurementView keyword={keyword} appId={appId} callback={() => setReload(moment().unix())}></MeasurementView>
            </Item>

            {expired && (
                <NoSubscriptionDialog
                    open={noSub}
                    setOpen={setNoSub}
                    subId={subId}
                    companyId={companyId}
                    callback={handleRenewSubscription}
                    appId={AppId.DEVICE_EXPLORER}
                />
            )}

            {!expired && (
                <NoSubscriptionInitiallyDialog
                    open={noSub}
                    setOpen={setNoSub}
                    subId={subId}
                    companyId={companyId}
                    callback={handleRenewSubscription}
                    appId={AppId.DEVICE_EXPLORER}
                />
            )}
        </Box>
    );
}
