import ApiService from "Services/apiService";
import moment from "moment";
import _ from "underscore";

const DashboardService = {
    requestThingsOfCluster: async (name, cluster) => {
        const promises = cluster.map(DashboardService.requestLastMeasurements);

        let clusterData1 = [];
        clusterData1 = await Promise.all(promises);
        clusterData1 = _.flatten(clusterData1, 1);

        let target = {};

        target["name"] = name;
        clusterData1.forEach((obj) => {
            if (obj != undefined) {
                Object.keys(obj).forEach((key) => {
                    target[key] = obj[key];
                });
            }
        });

        return target;
    },

    requestAllClustersThings: async (setRows, connectivity) => {
        let requestOptions = {
            url: `/stats/last/cluster_company`,
            data: {
                connectivity: connectivity,
            },
        };

        setRows([]);

        return ApiService.postData(requestOptions)
            .then((response) => {
                let result = [];
                response.forEach((ele) => {
                    let obj = { name: ele.name };
                    ele.stats.forEach((e) => {
                        let name = e.attribute.name + "_" + e.fixedName.substring(e.fixedName.lastIndexOf("-") + 1);
                        name = DashboardService.convertName(name, connectivity);
                        name = name.replaceAll(" ", "_");

                        obj[name] = {
                            value: e.value,
                            unit: e.attribute.unit == null ? "" : e.attribute.unit,
                            date: moment.unix(e.timestamp).format("LT"),
                            thingId: e.thingId,
                            thingName: e.thingName,
                            attributeId: e.attribute.id,
                            attributeName: e.attribute.name,
                            fixedName: e.fixedName,
                            alertCount: e.alertCount,
                        };

                        if (name != undefined && name.indexOf("soil_moisture_raw") != -1) {
                            obj[name].value = DashboardService.changeReadableFormat(obj[name].value);
                        }
                    });

                    result.push(obj);
                });

                setRows(result);
            })
            .catch((e) => console.log(e));
    },

    convertName: (name, connectivity) => {
        if (connectivity == "LORA") return name;

        // To match the name to the current code. this code was added after implementing LORA.
        // See the Cluster.js lines 130 ~ 217
        if (name == "light_001") {
            return name;
        }

        if (name == "temperature_001") {
            return "temperature_003";
        }

        if (name == "humidity_001") {
            return "humidity_003";
        }

        if (name == "o2perc_001") {
            return "o2perc_003";
        }

        if (name == "co2_001") {
            return "co2_003";
        }

        if (name == "pressure_001") {
            return "pressure_003";
        }
    },

    // thingName is MUST be devEUI
    requestLastMeasurements: async (thingName) => {
        let requestOptions = {
            url: `/stats/last/thingname/${thingName}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                let attributes = [];

                if (response.data.length > 0) {
                    response.data.forEach((e) => {
                        let obj = {};

                        // add postfix for each sensor because some sensors have same attributes
                        let sensorIdx = e.fixedName.substring(e.fixedName.lastIndexOf("-") + 1);
                        let attribute_name = e.attribute.name + "_" + sensorIdx;

                        obj[attribute_name] = {
                            value: e.value,
                            unit: e.attribute.unit == null ? "" : e.attribute.unit,
                            date: moment.unix(e.timestamp).format("LT"),
                            thingId: e.thingId,
                            thingName: thingName,
                            attributeId: e.attribute.id,
                            attributeName: e.attribute.name,
                            fixedName: e.fixedName,
                            alertCount: e.alertCount,
                        };

                        if (attribute_name.indexOf("soil_moisture_raw") != -1) {
                            obj[attribute_name].value = DashboardService.changeReadableFormat(obj[attribute_name].value);
                        }

                        attributes.push(obj);
                    });
                }

                return attributes;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    entity: (value, unit, date, fixedName) => {
        return { value, unit, date, fixedName };
    },

    getLabel: (attributeName) => {
        switch (attributeName) {
            case "ambient_humidity":
                return "Humidity";
            case "ambient_light":
                return "Light";
            case "ambient_temp":
                return "Temperature";
            case "ec":
                return "EC";
            case "humidity":
                return "Humidity";
            case "light":
                return "Light";
            case "photosynthetically_active_radiation":
                return "Photosynthetically Active Radiation";
            case "phvalue":
                return "PH";
            case "soil_moisture_raw":
                return "Moisture";
            case "soil_temp":
                return "Temperature";
            case "temperature":
                return "Temperature";
            case "o2perc":
                return "O2";
            case "co2":
                return "CO2";
            case "pressure":
                return "Pressure";
            case "lux":
                return "Light";
            default:
                return "Unknown";
        }
    },

    changeReadableFormat(value) {
        // for the dashboard
        if (value > 1399) {
            return "Dry";
        } else if (value <= 1341) {
            return "Wet";
        } else {
            return DashboardService.changeReadableFormat2(value);
        }
    },

    changeReadableFormat2(value) {
        // for detail page
        if (value > 1399) {
            return 0;
        } else if (value <= 1399 && value > 1396) {
            return 0.1;
        } else if (value <= 1396 && value > 1391) {
            return 0.2;
        } else if (value <= 1391 && value > 1386) {
            return 0.3;
        } else if (value <= 1386 && value > 1381) {
            return 0.4;
        } else if (value <= 1381 && value > 1376) {
            return 0.5;
        } else if (value <= 1376 && value > 1371) {
            return 0.6;
        } else if (value <= 1371 && value > 1366) {
            return 0.7;
        } else if (value <= 1366 && value > 1361) {
            return 0.8;
        } else if (value <= 1361 && value > 1356) {
            return 0.9;
        } else if (value <= 1356 && value > 1351) {
            return 1;
        } else if (value <= 1351 && value > 1346) {
            return 1.1;
        } else if (value <= 1346 && value > 1341) {
            return 1.2;
        } else if (value <= 1341) {
            return 1.3;
        }
    },

    requestAlertCountByCluster: async (setAlertCount) => {
        let requestOptions = {
            url: `/api/alerts/countByCluster`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);
                setAlertCount(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default DashboardService;
