const UsageColumns = {
    getColumns: function (t) {

        const columns = [

            {
                field: "id",
                headerName: "ID",
                flex: 1,
                editable: false,
                sortable: true,
                filterable: false,
            },
            {
                field: "baseMonth",
                headerName: t("col.period"),
                flex: 1,
                editable: false,
                sortable: false,
                filterable: false,
            },
            {
                field: "countThing",
                headerName: t("biz.things"),
                flex: 1,
                editable: false,
                sortable: false,
                filterable: false,
            },
            {
                field: "countGateway",
                headerName: t("biz.gateways"),
                flex: 1,
                editable: false,
                sortable: false,
                filterable: false,
            },
            {
                field: "countSms",
                headerName: t("biz.sms"),
                flex: 1,
                editable: false,
                sortable: false,
                filterable: false,
            },
            {
                field: "company",
                headerName: t("col.company"),
                flex: 1,
                editable: false,
                sortable: false,
                filterable: false,
            },
        ];

        return columns;
    },
};

export default UsageColumns;
