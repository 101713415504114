import ApiService from "Services/apiService";
import UserService from "Services/userService";

const RoomService = {
    saveSetting: async (thingId, delayThreshold, notifyOnClose, enableEmail, enableSms) => {
        let requestOptions = {
            url: `/v2/things/${thingId}/custom_fields`,
            data: [
                {
                    name: "EMERA_V2_CONTACT_SETTING",
                    label: "EMERA_V2_CONTACT_SETTING",
                    value: JSON.stringify({
                        delayThreshold,
                        notifyOnClose,
                        enableEmail,
                        enableSms,
                    }),
                    type: "TEXT",
                },
            ],
        };
        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestNotifications: async (setEmailNotify, setSmsNotify, appId) => {
        const user = await UserService.getAuthenticatedUser();

        let requestOptions = {
            url: `/api/notification?filter=company.name:eq_${user.company},system_notification.application_id:eq_${appId}`,
        };
        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                response.data.forEach((row) => {
                    // console.log(row);

                    // all rows have same email and phone
                    setEmailNotify(row.enableEmail);
                    setSmsNotify(row.enablePhone);
                });
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateName: async (thingId, thingName) => {
        let requestOptions = {
            url: `/v2/things`,
            data: {
                thingId,
                thingName
            },
        };
        // console.log("requestOptions", requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default RoomService;
