import ApiService from "Services/apiService";
import moment from "moment-timezone";
import UtilService from "Services/utilService";

export const PRODUCT_MILESIGHT_CT101 = {
    ID: "e7d4f8a1-b3c2-4e95-9f6d-28a7c5d91234",
    ATTRIBUTE_ID_HOURLY_CONSUMPTION: "814d1df1-0276-466a-877b-fdb261bd0ff0",
};

const GraphService = {
    requestHistoryAverage: async (thingId, interval, startDate, product) => {
        // console.log("requestHistoryAverage is called");

        let attributeId = "";
        switch (product.id) {
            case PRODUCT_MILESIGHT_CT101.ID:
                attributeId = PRODUCT_MILESIGHT_CT101.ATTRIBUTE_ID_HOURLY_CONSUMPTION;
                break;
            default:
                break;
        }

        const filter = `filter=thing.id:eq_${thingId},attribute.id:eq_${attributeId}`;

        let start = startDate;
        let end = startDate;

        let intervalVal = "";

        if (interval == "day") {
            start = moment(start).startOf("day");
            end = moment(startDate).add(1, "days").startOf("day");
            intervalVal = "hours";
        } else if (interval == "week") {
            start = moment(start).startOf("week");
            end = moment(startDate).endOf("week");
            intervalVal = "days";
        } else if (interval == "month") {
            start = moment(start).startOf("month");
            end = moment(startDate).endOf("month");
            intervalVal = "days";
        } else if (interval == "year") {
            start = moment(start).startOf("year");
            end = moment(startDate).endOf("year");
            intervalVal = "months";
        }

        const period = `&start=${moment(start).valueOf()}&end=${moment(end).valueOf()}`;

        let requestOptions = {
            url: `/stats/measurements/all?${filter}&interval=${intervalVal}${period}&time=1`,
        };

        // console.log("requestOptions...........................");
        // console.log(requestOptions);
        // console.log(start + ", " + moment(start).format("LLLL"))
        // console.log(end + ", " + moment(end).format("LLLL"))
        // console.log("attributeId=" + attributeId)

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response)

                let chartData = [];

                if (interval == "day") {
                    response.forEach((ele) => {
                        const baseTime = ele.start;
                        chartData.push([UtilService.formateH(baseTime), parseFloat(parseFloat(ele.value).toFixed(2)), ele.unit, baseTime]);
                    });
                } else if (interval == "month") {
                    const sumByDay = {};
                    response.forEach((data) => {
                        // America/Los_Angeles or America/Vancouver
                        // America/New_York
                        const date = moment.utc(data.start);
                        const day = date.format("YYYY-MM-DD");

                        // let mark = data.start == '1689400087000' ? "-> " : "";
                        // console.log(mark + "" + data.start + ", " + date.format('LLLL') + ", " + data.value);

                        if (sumByDay[day]) {
                            sumByDay[day].value += data.value;
                        } else {
                            sumByDay[day] = {
                                value: data.value,
                                start: data.start,
                                unit: data.unit,
                            };
                        }
                    });

                    // console.log(sumByDay)

                    for (const day in sumByDay) {
                        // console.log(`Date: ${day}, Sum: ${sumByDay[day].value} gallons`);
                        const baseTime = sumByDay[day].start;
                        chartData.push([UtilService.formatDo(baseTime), parseFloat(parseFloat(sumByDay[day].value).toFixed(1)), sumByDay[day].value, baseTime]);
                    }
                } else if (interval == "year") {
                    const someByMonth = {};
                    response.forEach((data) => {
                        // America/Los_Angeles or America/Vancouver
                        // America/New_York
                        const date = moment.utc(data.start);
                        const month = date.format("YYYY-MM");

                        // let mark = data.start == '1689400087000' ? "-> " : "";
                        // console.log(mark + "" + data.start + ", " + date.format('LLLL') + ", " + data.value);

                        if (someByMonth[month]) {
                            someByMonth[month].value += data.value;
                        } else {
                            someByMonth[month] = {
                                value: data.value,
                                start: data.start,
                                unit: data.unit,
                            };
                        }
                    });

                    // console.log(someByMonth)

                    for (const month in someByMonth) {
                        // console.log(`Date: ${month}, Sum: ${someByMonth[month].value} gallons`);
                        const baseTime = someByMonth[month].start;

                        // console.log(`baseTime: ${baseTime}`);
                        chartData.push([
                            UtilService.formatM(baseTime),
                            parseFloat(parseFloat(someByMonth[month].value).toFixed(1)),
                            someByMonth[month].value,
                            baseTime,
                        ]);
                    }
                }

                // console.log(chartData)

                return chartData;
            })
            .catch((e) => {
                console.error(e);
            });
    },
    
};

export default GraphService;
