import ApiService from "Services/apiService"; 

const UsageService = { 
    requetThingHistory: async (companyId, page, pageSize, sort, setRows, setRowCount) => {
        let requestOptions = {
            url: "/v2/usage_history",
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => s.field + ":" + s.sort).join(","),
            },
        };

        if (companyId) {
            requestOptions.params["companyId"] = companyId;
        }

        // console.log("requetCompanies..");
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            let mapped = r.content
                .filter((row) => row.baseMonth)
                .map((row) => {
                    const original = row.baseMonth;
                    const formatted = original.slice(0, 4) + "-" + original.slice(4, 6);
                    return {
                        id: row.company.id + ", " + row.baseMonth,
                        company: row.company.name,
                        baseMonth: formatted,
                        countThing: row.countThing === 0 ? "" : row.countThing,
                        countGateway: row.countGateway === 0 ? "" : row.countGateway,
                        countSms: row.countSms === 0 ? "" : row.countSms,
                    };
                });

            // console.log(mapped);
            // console.log(r.totalElements);

            setRows(mapped);
            setRowCount(r.totalElements);
        });
    },
};

export default UsageService;
