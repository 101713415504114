import ApiService from "Services/apiService";

const CardService = {
    requestLastMeasures: async (thingId, attributeIds) => {
        let requestOptions = {
            url: `/stats/last/things/${thingId}`,
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions).then((response) => {
            // console.log(response);

            return response.data.filter((r) => attributeIds.includes(r.attribute.id));
        });
    },

    requestThing: async (thingId) => {
        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions).then((response) => {
            // console.log(response);
            return response;
        });
    },
};

export default CardService;
