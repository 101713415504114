import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SettingMain from "./SettingMain";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailSetting from "./EmailSetting";
import UserService from "Services/userService";
import MeasurementServiceV2 from "Routes/deviceExplorer/measurements/MeasurementServiceV2";
import { useHistory } from "react-router-dom";
import basicAuthService from "../../../services/basicAuthService";

export default function SviSettingMain() {
    const [value, setValue] = useState("0");
    const [positions, setPositions] = useState();
    const history = useHistory();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        // console.log("uef in SviSettingMain");
        UserService.getAuthenticatedUser().then((user) => {
            const requestFunction =
                user.authorities === "USER"
                    ? () =>
                          MeasurementServiceV2.requestByUser("", 0, 10, undefined, undefined, [{ field: "name", sort: "DESC" }], user.id).catch((e) => {
                              //   console.log("Setting, User, Session expired", e);
                              basicAuthService.logout();
                              window.location.href = "/apps";
                          })
                    : () =>
                          MeasurementServiceV2.requestData("", 0, 10, undefined, undefined, [{ field: "name", sort: "DESC" }]).catch((e) => {
                              //   console.log("Setting, Admin, Session expired", e);
                              basicAuthService.logout();
                              window.location.href = "/apps";
                          });

            requestFunction().then((r) => {
                // console.log("r", r);

                const mapped = r.map((item) => ({
                    name: item.name,
                    thingId: item.id,
                    siteName: item["site.name"],
                }));
                setPositions(mapped);
            });
        });
    }, []);

    return (
        <Box sx={{ flexGrow: 1, pt: 10, ml: 2, mr: 2 }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {positions?.map((ele, idx) => (
                            <Tab label={ele.name} value={idx.toString()} key={idx} />
                        ))}
                        {positions && <Tab icon={<SettingsIcon />} value="settings" aria-label="settings" />}
                    </TabList>
                </Box>

                {positions &&
                    positions.map((ele, idx) => {
                        return (
                            <TabPanel value={idx.toString()} key={idx}>
                                <Stack gap={5}>
                                    <SettingMain siteName={ele.name} thingId={ele.thingId} />
                                </Stack>
                            </TabPanel>
                        );
                    })}
                <TabPanel value="settings">
                    <EmailSetting />
                </TabPanel>
            </TabContext>
        </Box>
    );
}
