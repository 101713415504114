import ApiService from "Services/apiService";
import _ from "underscore";
import dayjs from "dayjs";

const ChartContactorService = {
    requestMeasures: async (thingId, attributeId) => {
        const end = dayjs().unix();
        const start = dayjs().subtract(1, "month").unix();

        let requestOptions = {
            url: `/v2/measures`,
            params: {
                thingId,
                attributeId,
                start,
                end,
            },
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                return response.map((ele) => {
                    return {
                        x: ele.start,
                        x2: ele.end,
                        y: ele.value === "open" ? 1 : 0,
                        duration: ele.duration,
                        color: ele.value === "open" ? '#168714' : '#D12427',
                    };
                }); 
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default ChartContactorService;
