import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import UserService from "Services/userService";
import CreateCompanyDialog from "./CreateCompanyDialog";
import { CompanyListColumn } from "./CompanyListColumn";
import moment from "moment";
import ThingList from "./ThingList";
import CompanyService from "./CompanyService";
import { CustomButton } from "../../../components/ui/CustomButton";
import Stack from "@mui/material/Stack";
import CustomConfirmDialog from "../../../components/ui/CustomConfirmDialog";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { LicenseInfo } from "@mui/x-license-pro";
import { Grid } from "@mui/material";
import SearchBox from "Components/ui/SearchBox";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function CompanyList() {
    const { t, i18n } = useTranslation();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState([{ field: "name", sort: "asc" }]);

    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState();
    const [selectedCompanyId, setSelectedCompanyId] = useState();
    const [selectedCompanyName, setSelectedCompanyName] = useState();
    const [selectionModel, setSelectionModel] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [authorities, setAuthorities] = useState();
    const [keyword, setKeyword] = useState("");

    const [userCompany, setUserCompany] = useState();
    const [userCompanyId, setUserCompanyId] = useState();

    const columns = CompanyListColumn(t);

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            // console.log("useEffect in CompanyList");
            // console.log(user);

            setUserCompany(user.company);
            setUserCompanyId(user.companyId);
            setAuthorities(user.authorities);

            if (user.authorities == "SUPER_ADMIN") {
                CompanyService.requestCompany(paginationModel.page, paginationModel.pageSize, sort, setRows, setRowCount);
            } else {
                CompanyService.requestSubCompany(user.company, paginationModel.page, paginationModel.pageSize, sort, setRows, setRowCount);
            }
        });
    }, [reload, sort, paginationModel.page, paginationModel.pageSize, selectionModel]);

    const handleCellClick = (params) => {
        // console.log(params);
        // if (params.field == "__check__") {
        //     return;
        // }
        setSelectedCompanyId(params.row.id);
        setSelectedCompanyName(params.row.name);
        setSelectionModel([params.row.id]);
    };

    const handleSelectionModel = (param) => {
        // console.log("handleSelectionModel...");
        // console.log(param);

        if (param.length > 1) {
            const selectedRow = param[param.length - 1];

            // console.log(selectedRow);
            setSelectionModel([selectedRow]);
        } else {
            setSelectionModel(param);
        }
    };

    const handleAddUser = () => {
        setOpen(true);
    };

    const handleCallback = () => {
        setReload(moment().unix());
    };

    const handleConfirm = () => {
        // console.log("Delete....");
        // console.log(selectedCompanyName);

        if (selectedCompanyId) {
            CompanyService.removeGroup(selectedCompanyId).then(() => setReload(moment()));
        }

        // if (selectionModel && selectionModel.length > 0) {
        //     CompanyService.removeGroup(selectionModel[0]).then(() => setReload(moment()));
        // }
    };

    const handleSortModelChange = (params) => {
        // console.log(params);
        setSort(params);
    };

    return (
        <Box sx={{ height: "100vh", p: 2, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={5} sx={{ display: "flex", flexDirection: "row", overflow: "auto" }}>
                    <Stack direction={"column"} spacing={2} sx={{ pt: 8, width: "100%" }}>
                        <Stack direction="row" spacing={2}>
                            <CustomButton name={t("btn.add")} callback={() => handleAddUser()} />
                            <CustomButton
                                name={t("btn.remove")}
                                callback={() => {
                                    if (selectedCompanyName) {
                                        setOpenConfirm(true);
                                    }
                                }}
                            />
                        </Stack>
                        <DataGridPro
                            rows={rows}
                            rowCount={rowCount}
                            columns={columns}
                            disableColumnFilter
                            paginationModel={paginationModel}
                            pagination={true}
                            paginationMode="server"
                            sortingMode="server"
                            pageSizeOptions={[10, 20]}
                            onPaginationModelChange={setPaginationModel}
                            // onRowSelectionModelChange={handleSelectionModel}
                            density="compact"
                            onCellClick={handleCellClick}
                            disableSelectionOnClick
                            disableRowSelectionOnClick={false}
                            checkboxSelection={false}
                            disableMultipleSelection={true}
                            // style={{ height: "100%" }}
                            // headerHeight={0}
                            // hideFooterRowCount
                            hideFooterSelectedRowCount
                            onSortModelChange={handleSortModelChange}
                            selectionModel={selectionModel}
                            sx={{
                                "& .MuiTablePagination-selectLabel": {
                                    marginTop: 2,
                                },
                                "& .MuiTablePagination-displayedRows": {
                                    marginTop: 2,
                                },
                                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    display: "none",
                                },
                                "& .MuiDataGrid-row.Mui-selected": {
                                    backgroundColor: "#B7D9FB",
                                },
                            }}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={7} sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", mt: 8, ml: 2 }}>
                        <SearchBox callback={setKeyword} />
                    </Box>
                    <ThingList selectedCompanyId={selectedCompanyId} isCompanyPage={true} parentReload={reload} keyword={keyword} />
                </Grid>
            </Grid>

            <CreateCompanyDialog
                open={open}
                setOpen={setOpen}
                callback={handleCallback}
                company={userCompany}
                authorities={authorities}
                selectedCompanyId={userCompanyId}
            />

            <CustomConfirmDialog
                open={openConfirm}
                setOpen={setOpenConfirm}
                title={t("de.group_del_title")}
                message={[t("de.group_del_msg_1") + " " + selectedCompanyName + " " + t("de.group_del_msg_2"), t("de.group_del_msg_3")]}
                callback={handleConfirm}
            />
        </Box>
    );
}
