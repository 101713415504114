import { useEffect, useState } from "react";
import RoomCard from "./card/RoomCard";
import Box from "@mui/material/Box";
import RoomElectricityCard from "./card/RoomElectricityCard";
import RoomContainerService from "./RoomContainerService";
import RoomContactorCard from "./card/RoomContactorCard";
import UserService from "Services/userService";

export default function RoomContainer({ selectedSiteId, moyenne, setMoyenne, parentReload }) {
    const [assetAttribute, setAssetAttribute] = useState([]);
    const [reloadParent, setReloadParent] = useState(0);

    useEffect(() => {
        let isMounted = true;

        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u);

            RoomContainerService.requestAsset(u.companyId).then((r) => {
                if (isMounted) {
                    // console.log(r);
                    setAssetAttribute(r);
                }
            });
        });

        return () => {
            isMounted = false;
        };
    }, [selectedSiteId, parentReload, moyenne, reloadParent]);

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {assetAttribute
                .filter((a) => selectedSiteId === 0 || (a.site && selectedSiteId === a.site.id))
                .map((row) => {
                    // console.log(row);
                    if (row.assetAttribute.some((a) => a.attribute.name === "averageAmps")) {
                        return <RoomElectricityCard asset={row} key={row.assetId} moyenne={moyenne} setReloadParent={setReloadParent} />;
                    } else if (row.assetAttribute.some((a) => a.attribute.name === "current")) {
                        // console.log("current");
                        // console.log("row", row);

                        // Filtering the only current attribute
                        let updatedRow = { ...row, assetAttribute: row.assetAttribute.filter((a) => a.attribute.name === "current") };

                        return <RoomElectricityCard asset={updatedRow} key={row.assetId} moyenne={moyenne} setReloadParent={setReloadParent} />;
                    } else if (row.assetAttribute.some((a) => a.attribute.name === "magnet_status")) {
                        return <RoomContactorCard asset={row} key={row.assetId} moyenne={moyenne} setReloadParent={setReloadParent} />;
                    } else if (row && row.length !== 0) {
                        return <RoomCard asset={row} key={row.assetId} entity={row} moyenne={moyenne} setMoyenne={setMoyenne} setReloadParent={setReloadParent} />;
                    }
                })}
        </Box>
    );
}
