import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { blueGrey } from "@mui/material/colors";
import { debounce } from "lodash";

export default function SearchBox({ callback }) {
    const [searchText, setSearchText] = useState("");

    const debouncedCallback = useCallback(
        debounce((value) => {
            callback(value);
        }, 500),
        [callback]
    );

    const handleChange = (e) => {
        // console.log("handleChange", e.target.value);

        const value = e.target.value;
        setSearchText(value);
        debouncedCallback(value);
    };

    return (
        <Box sx={{ position: "relative", borderRadius: 1, backgroundColor: blueGrey[100] }}>
            <Box sx={{ pl: 1, pr: 1, position: "absolute", display: "flex", alignItems: "center", height: "34px" }}>
                <SearchIcon />
            </Box>
            <InputBase
                sx={{ color: "inherit", paddingLeft: 4 }}
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={handleChange}
                value={searchText}
            />
        </Box>
    );
}
