import ApiService from "Services/apiService";
import validator from "validator";

const CompanyService = {
    requestAllCompany: async () => {
        let requestOptions = {
            url: `/v2/companies`,
            params: {
                page: 0,
                pageSize: 100,
                sort: "name:asc",
            },
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.content.map((r) => {
            return {
                id: r.id,
                name: r.name,
                // name: r.name.replace("COMPANY_", ""),
            };
        });

        return mapped;
    },

    requestCompany: async (page, pageSize, sort, setRows, setRowCount) => {
        let requestOptions = {
            url: `/v2/companies`,
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => s.field + ":" + s.sort).join(","),
            },
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.content.map((r) => {
            // console.log(r);

            return {
                id: r.id,
                name: r.name,
                // name: r.name.replace("COMPANY_", ""),
            };
        });
        // console.log(mapped)
        setRows(mapped);
        setRowCount(result.totalElements);

        return mapped;
    },

    requestSubCompany: async (company, page, pageSize, sort, setRows, setRowCount) => {
        let requestOptions = {
            url: `/v2/companies/sub`,
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => s.field + ":" + s.sort).join(","),
                companyName: company,
            },
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.content.map((r) => {
            return {
                id: r.id,
                name: r.name,
                // name: r.name.replace("COMPANY_", ""),
            };
        });
        // console.log(mapped)
        setRows(mapped);
        setRowCount(result.totalElements);

        return mapped;
    },

    requestSubCompanyWithMine: async (company) => {
        let requestOptions = {
            url: `/v2/companies/sub_with_mine`,
            params: {
                companyName: company,
            },
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        const mapped = result.map((r) => {
            return {
                id: r.id,
                name: r.name,
                // name: r.name.replace("COMPANY_", ""),
            };
        });
        // console.log(mapped)

        return mapped;
    },

    createGroup: async (company, groupName, authorities, dataRetention, selectedCompanyId) => {
        let requestOptions = {
            url: `/v2/companies`,
            data: {
                companyName: company,
                subCompanyName: groupName,
                authorities: authorities,
                dataRetention: dataRetention,
            },
        };

        if (selectedCompanyId) {
            requestOptions.data.parentCompanyId = selectedCompanyId;
        }

        // console.log(requestOptions);

        const result = await ApiService.postData(requestOptions).catch((e) => console.error(e));
        // console.log(result);
        return result;
    },

    requestThingByCompanyId: async (selectedCompanyId) => {
        if (validator.isEmpty(selectedCompanyId)) {
            return;
        }

        let requestOptions = {
            url: `/v2/companies/${selectedCompanyId}/things`,
        };

        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        // console.log(result);
        return result;
    },

    assignThings: async (selectedCompanyId, model, rowIds) => {
        let requestOptions = {
            url: `/v2/companies/assign`,
            data: {
                selectedCompanyId: selectedCompanyId,
                selectedThingIds: model,
                rowIds: rowIds,
            },
        };
        // console.log(requestOptions);

        const result = await ApiService.putData(requestOptions);
        // console.log(result);
        return result;
    },

    removeGroup: async (companyId) => {
        let requestOptions = {
            url: `/v2/companies/${companyId}`,
        };

        // console.log(requestOptions);

        const result = await ApiService.deleteData(requestOptions);
        // console.log(result);
        return result;
    },
};

export default CompanyService;
