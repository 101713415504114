import { useState, useEffect, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import SettingContext from "Providers/SettingContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomStyle from "Components/CustomStyle";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import validator from "validator";
import NotificationService from "./NotificationService";
import { useTranslation } from "react-i18next";
import { AppId } from "Components/applications/ApplicationData";

export default function NotificationSetting({ appId }) {
    const { t, i18n } = useTranslation();

    let defaltError = {
        email: false,
        phone: false,
        message: "",
    };
    const [errors, setErrors] = useState(defaltError);

    const setting = useContext(SettingContext);
    const [open, setOpen] = useState(false);

    // ----
    const [lastPhone, setLastPhone] = useState("");
    const [lastEmail, setLastEmail] = useState("");
    const [countThisMonth, setCountThisMonth] = useState(0);

    const [isWarning, setIsWarning] = useState(false);
    const [isWarningEmail, setIsWarningEmail] = useState(false);
    const [isWarningPhone, setIsWarningPhone] = useState(false);

    const [isInfo, setIsInfo] = useState(false);
    const [isInfoEmail, setIsInfoEmail] = useState(false);
    const [isInfoPhone, setIsInfoPhone] = useState(false);

    const [isCritical, setIsCritical] = useState(false);
    const [isCriticalEmail, setIsCriticalEmail] = useState(false);
    const [isCriticalPhone, setIsCriticalPhone] = useState(false);

    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        NotificationService.requestLastNotifications(setLastPhone, setLastEmail);

        NotificationService.requestCountThisMonth(setCountThisMonth);

        NotificationService.requestNotifications(
            setIsWarning,
            setIsWarningEmail,
            setIsWarningPhone,
            setIsInfo,
            setIsInfoEmail,
            setIsInfoPhone,
            setIsCritical,
            setIsCriticalEmail,
            setIsCriticalPhone,
            setEmail,
            setPhone,
            appId
        );
    }, [appId, open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setEmail("");
        setPhone("");
        setOpen(false);
    };

    const handleSubscribe = () => {
        if (errors.email || errors.phone) {
            return;
        }

        if (appId === AppId.EMERA_V2) {
            // Set all notification to true
            // Each sensor has its own notification setting (enable email and phone)
            NotificationService.updateNotification(true, true, true, true, true, true, true, true, true, email, phone, handleClose, appId);
        } else {
            NotificationService.updateNotification(
                isWarning,
                isWarningEmail,
                isWarningPhone,
                isInfo,
                isInfoEmail,
                isInfoPhone,
                isCritical,
                isCriticalEmail,
                isCriticalPhone,
                email,
                phone,
                handleClose,
                appId
            );
        }
    };

    const handleOnOff = (e) => {
        if (e.target.name == "swWarning") {
            setIsWarning(e.target.checked);
        } else if (e.target.name == "swInfo") {
            setIsInfo(e.target.checked);
        } else if (e.target.name == "swCritical") {
            setIsCritical(e.target.checked);
        }
    };

    const handleEmail = (e) => {
        if (e.target.name == "ckWarningEmail") {
            setIsWarningEmail(e.target.checked);
        } else if (e.target.name == "ckInfoEmail") {
            setIsInfoEmail(e.target.checked);
        } else if (e.target.name == "ckCriticalEmail") {
            setIsCriticalEmail(e.target.checked);
        }
    };

    const handlePhone = (e) => {
        if (e.target.name == "ckWarningPhone") {
            setIsWarningPhone(e.target.checked);
        } else if (e.target.name == "ckInfoPhone") {
            setIsInfoPhone(e.target.checked);
        } else if (e.target.name == "ckCriticalPhone") {
            setIsCriticalPhone(e.target.checked);
        }
    };

    const handleValue = (e) => {
        if (e.target.name == "txtEmail") {
            setEmail(e.target.value);

            // Split emails by comma and trim whitespace
            const emails = e.target.value.split(",").map((email) => email.trim());
            const isValidEmails = emails.every((email) => validator.isEmail(email));

            setErrors({
                email: !isValidEmails,
                phone: errors.phone,
            });
        } else if (e.target.name == "txtPhone") {
            setPhone(e.target.value);

            // Split phone numbers by comma and trim whitespace
            const phones = e.target.value.split(",").map((phone) => phone.trim());
            const regex = /\+\d+/; // ex) +1

            const isValidPhones = phones.every(
                (phone) => validator.isMobilePhone(phone, ["en-CA", "fr-FR", "en-US"], [{ strictMode: true }]) && regex.test(phone)
            );

            setErrors({
                email: errors.email,
                phone: !isValidPhones,
            });
        }
    };

    return (
        <>
            <IconButton aria-label="delete" onClick={handleClickOpen} size="large">
                <i className="fa fa-bell-o" aria-hidden="true" style={{ color: "white" }}></i>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t("superviot.notifs")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} alignItems="center">
                        {appId !== AppId.EMERA_V2 && (
                            <>
                                <Grid item xs={8} sm={8}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    Email
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    SMS
                                </Grid>

                                {/* Info */}
                                <Grid item xs={4} sm={4}>
                                    <Typography>{t("superviot.info")}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Switch name="swInfo" checked={isInfo} onChange={(e) => handleOnOff(e)} inputProps={{ "aria-label": "controlled" }} />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Checkbox
                                        name="ckInfoEmail"
                                        checked={isInfoEmail}
                                        onChange={(e) => handleEmail(e)}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Checkbox
                                        name="ckInfoPhone"
                                        checked={isInfoPhone}
                                        onChange={(e) => handlePhone(e)}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                </Grid>

                                {/* Warning */}
                                <Grid item xs={4} sm={4}>
                                    <Typography>{t("superviot.warning")}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Switch name="swWarning" checked={isWarning} onChange={(e) => handleOnOff(e)} inputProps={{ "aria-label": "controlled" }} />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Checkbox
                                        name="ckWarningEmail"
                                        checked={isWarningEmail}
                                        onChange={(e) => handleEmail(e)}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Checkbox
                                        name="ckWarningPhone"
                                        checked={isWarningPhone}
                                        onChange={(e) => handlePhone(e)}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                </Grid>

                                {/* Critical */}
                                <Grid item xs={4} sm={4}>
                                    <Typography>{t("superviot.critical")}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Switch
                                        name="swCritical"
                                        checked={isCritical}
                                        onChange={(e) => handleOnOff(e)}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Checkbox
                                        name="ckCriticalEmail"
                                        checked={isCriticalEmail}
                                        onChange={(e) => handleEmail(e)}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Checkbox
                                        name="ckCriticalPhone"
                                        checked={isCriticalPhone}
                                        onChange={(e) => handlePhone(e)}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                </Grid>
                            </>
                        )}

                        {/* End of setup section */}
                        <Grid item xs={3} sm={3}>
                            <Typography>Email</Typography>
                        </Grid>
                        <Grid item xs={9} sm={9}>
                            <TextField
                                required
                                name="txtEmail"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={email}
                                onChange={(e) => handleValue(e)}
                                error={errors.email ? true : false}
                                sx={CustomStyle.RemoveLabel}
                                placeholder="email1@example.com, email2@example.com"
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Typography>{t("superviot.last_email")}</Typography>
                        </Grid>
                        <Grid item xs={9} sm={9}>
                            {lastEmail?.email}
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Typography>{t("superviot.phone")}</Typography>
                        </Grid>
                        <Grid item xs={9} sm={9}>
                            <TextField
                                required
                                name="txtPhone"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={phone}
                                onChange={(e) => handleValue(e)}
                                error={errors.phone ? true : false}
                                sx={CustomStyle.RemoveLabel}
                                placeholder="+1 514-999-9999, +1 514-888-8888"
                                disabled={appId === AppId.SUPERVIOT ? true : false}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Typography>{t("superviot.last_phone")}</Typography>
                        </Grid>
                        <Grid item xs={9} sm={9}>
                            {lastPhone?.phoneNumber}
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Typography>{t("superviot.sms_this_month")}</Typography>
                        </Grid>
                        <Grid item xs={9} sm={9}>
                            {(countThisMonth && countThisMonth?.find((item) => item.type === "PHONE")?.total) || 0}
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>{t("btn.cancel")}</Button>
                    <Button onClick={handleSubscribe}>{t("btn.ok")}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
