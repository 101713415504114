import React, { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CustomStyledButton from "Components/ui/CustomStyledButton";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AddStationPopup from "./AddStationPopup";
import { BootstrapDialog, BootstrapDialogTitle } from "Components/ui/BootstrapDialog";
import { getVisibleSettingColumns } from "./VisibilityColumn";
import VisibilityService from "./VisibilityService";
import { frFR } from "@mui/x-data-grid/locales";
import { AppId } from "Components/applications/ApplicationData";

export default function VisibilityMain({ open, onClose, setReloadParent, visibleStation = false, selectedSiteId, appId }) {
    const { t, i18n } = useTranslation();

    const [paging, setPaging] = useState({ page: 0, pageSize: 10 });
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState([{ field: "name", sort: "asc" }]);
    const [rows, setRows] = useState([]);

    const [openCreateStation, setOpenCreateStation] = useState(false);

    useEffect(() => {
        if (appId === AppId.SMART_AGRICULTURE) {
            VisibilityService.requestThing2(paging.page, paging.pageSize, "", setRows, setRowCount, selectedSiteId, appId).then((r) => {
                // console.log(r);
            });
        } else {
            VisibilityService.requestThing(paging.page, paging.pageSize, "", setRows, setRowCount, selectedSiteId, appId).then((r) => {
                // console.log(r);
            });
        }

    }, [paging.page, paging.pageSize, selectedSiteId, appId]);

    const handleVisibilityChange = async (id) => {
        console.log("handleVisibilityChange", id);

        // Find current sensor and its visibility state
        const sensor = rows.find((s) => s.id === id);
        const newVisibility = !sensor.visible;

        try {
            if (selectedSiteId) {
                await VisibilityService.updateVisibilityBySite(id, newVisibility, selectedSiteId, appId).then(() => setReloadParent());
            } else {
                // Call API to update visibility (assuming this method exists in your service)
                await VisibilityService.updateVisibility(id, newVisibility).then(() => setReloadParent());
            }

            // Update local state only after successful API call
            setRows(rows.map((s) => (s.id === id ? { ...s, visible: newVisibility } : s)));
        } catch (error) {
            console.error("Failed to update visibility:", error);
            // Optionally add error handling (show toast, etc.)
        }
    };

    const handleAddStation = () => {
        setOpenCreateStation(true);
    };

    const handleOk = () => {
        onClose();
    };

    const columns = getVisibleSettingColumns(t, handleVisibilityChange);

    return (
        <BootstrapDialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                {t("superviot.visibility_settings")}
            </BootstrapDialogTitle>
            <DialogContent>
                <div style={{ height: 400, width: "100%" }}>
                    <DataGridPro
                        rows={rows}
                        rowCount={rowCount}
                        columns={columns}
                        disableRowSelectionOnClick
                        pagination={true}
                        paginationMode="server"
                        paginationModel={paging}
                        onPaginationModelChange={setPaging}
                        sortModel={sort}
                        sortingMode="server"
                        pageSizeOptions={[10, 50]}
                        onSortModelChange={setSort}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2,
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2,
                            },
                        }}
                        density="compact"
                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                    />
                </div>
            </DialogContent>

            <DialogActions sx={{ mr: 2, mb: 2 }}>
                {visibleStation && <CustomStyledButton callback={handleAddStation} name={t("superviot.add_station")} />}
                <CustomStyledButton callback={handleOk} name={t("btn.ok")} />
            </DialogActions>

            <AddStationPopup open={openCreateStation} onClose={() => setOpenCreateStation(false)} />
        </BootstrapDialog>
    );
}
