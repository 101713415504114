import { Autocomplete, TextField } from "@mui/material";
import { getCode, getNames } from "country-list";
import { useState, useEffect } from "react";

export default function CountryAutocomplete({ onChange, selectedCode, onSelect }) {
    const [entity, setEntity] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null); // Initialize as null first

    useEffect(() => {
        // Set up the entity data
        const countryData = getNames().map((name) => ({
            label: name,
            code: getCode(name),
        }));
        setEntity(countryData);
        
        // Now that we have entity data, we can set the selected value
        const selected = countryData.find((option) => option.code === selectedCode);
        setSelectedValue(selected || null);
    }, [selectedCode]);

    const handleClick = (selectedItem) => {
        // console.log("Selected item:", selectedItem);
        setSelectedValue(selectedItem); // Update the local state
        onSelect(selectedItem.code);
    };

    return (
        <Autocomplete
            options={entity}
            value={selectedValue} // Use the local state
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
                <li {...props}>
                    <img src={option.flag} alt="" width="20" style={{ marginRight: 10 }} />
                    {option.label}
                </li>
            )}
            renderInput={(params) => <TextField {...params} label="Select a country" />}
            onChange={(event, value) => {
                onChange(value);
                handleClick(value);
            }}
            fullWidth
        />
    );
}
