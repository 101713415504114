import React, { useEffect } from "react";
import { Box } from "@mui/material";
import ChartElectricity from "./ChartElectricity";
import { useParams } from "react-router-dom";
import GraphMain from "./GraphMain";
import moment from "moment";

export default function ChartElectricityMain() {
    const { thingId } = useParams();

    useEffect(() => {
        // console.log("ChartElectricityMain", thingId);
    }, [thingId]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <ChartElectricity thingId={thingId} />
            <GraphMain selectedThingId={thingId} user={{ zoneinfo: moment.tz.guess() }} />
        </Box>
    );
}
