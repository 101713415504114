import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SiteDialogue from "Components/site/SiteDialogue";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SensorDetailService from "./SensorDetailService";

export default function SiteDetail({  setReload, thing }) {
    const [site, setSite] = useState("");
    const [tenant, setTenant] = useState("");
    const [openSite, setOpenSite] = useState(false);


    useEffect(() => {
        // console.log("thing", thing);

        if (thing && thing.site) {
            setSite(thing.site.name);
        }
    }, [thing]);

    const handleSite = (siteId) => {
        // console.log("handleSite", siteId);
        SensorDetailService.saveSite(thing.id, siteId).then(() => setReload(moment().unix()));
    };

    return (
        <Paper elevation={3} sx={{ height: "100%" }}>

            <Box>
                <Grid container elevation={3} sx={{ overflow: "hidden", height: "100%", width: "100%", p: 2 }}>
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", width: "100%" }}>
                            <TextField
                                id="txtZone"
                                variant="outlined"
                                value={site}
                                onChange={(e) => setSite(e.target.value)}
                                fullWidth
                                size="small"
                                label="Site"
                            />
                            <IconButton aria-label="delete" size="large" variant="contained" onClick={() => setOpenSite(true)}>
                                <MoreHorizIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="txtTenant"
                            variant="outlined"
                            value={tenant}
                            onChange={(e) => setTenant(e.target.value)}
                            fullWidth
                            size="small"
                            label="Tenant"
                        />
                    </Grid>

                    <SiteDialogue
                        open={openSite}
                        setOpen={setOpenSite}
                        selectedThingId={thing.id}
                        setReloadParent={(siteId) => {
                            // console.log("setReloadParent", siteId);
                            handleSite(siteId);
                            setReload(moment().unix());                     }}

                    />
                </Grid>
            </Box>
        </Paper>
    );
}
