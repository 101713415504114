import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import AddrService from "./AddrService";

export default function AddrAutoComplete({ addr, callback }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedAddress, setSelectedAddress] = useState(addr);

    useEffect(() => {
        let active = true;

        if (!open || !selectedAddress?.label) {
            setOptions([]);
            return undefined;
        }

        (async () => {
            setLoading(true);

            try {
                const response = await AddrService.verifyAddress(selectedAddress ? selectedAddress.label : "");

                if (active) {
                    // console.log(response);
                    setOptions(response || []); // Ensure response is an array
                }
            } catch (error) {
                console.error("Failed to fetch addresses:", error);
                if (active) {
                    setOptions([]); // Fallback to an empty array on error
                }
            } finally {
                if (active) {
                    setLoading(false);
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [open, selectedAddress]);

    useEffect(() => {
        setSelectedAddress(addr);
    }, [addr]);

    const handleSelectionChange = (event, value) => {
        // console.log(value);
        setSelectedAddress(value);

        if (callback) callback(value);
    };

    const handleText = (e) => {
        // console.log(e.target.value);
        setSelectedAddress({ label: e.target.value.toString() });

        if (callback) callback({ label: e.target.value });
    };

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionLabel={(option) => {
                return option.label;
            }}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label=""
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    onChange={handleText}
                    rows={3}
                    multiline
                />
            )}
            size="small"
            onChange={handleSelectionChange}
            isOptionEqualToValue={(option, value) => {
                return option.label === value.label;
            }}
            value={selectedAddress}
            fullWidth
            freeSolo={true}
            filterOptions={(options, state) => {
                // options should be returned, if not options does
                return options;
            }}
        />
    );
}
