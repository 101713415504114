import ApiService from "Services/apiService";

const CompanyService = {
    addCompany: async (companyName, days, selectedCompanyId) => {
        let requestOptions = {
            url: `/v2/companies/retention`,
            data: {
                companyName: companyName.toUpperCase(),
                retentionDays: days,
            },
        };

        if (selectedCompanyId) {
            requestOptions.data.parentCompanyId = selectedCompanyId;
        }
        // console.log(requestOptions);

        const response = await ApiService.postData(requestOptions);
        // console.log(response);

        return response;
    },
};

export default CompanyService;
