import * as minio from "minio";
import MinioService from "Routes/superviot/maint/MinioService";
import ApiService from "Services/apiService";

const TestService = {
    requestIdToken: async () => {
        const param = {
            url: "/idToken"
        }

        return await ApiService.getData(param).then(r => {
            // console.log(r);
            // console.log(r.idToken);
            return r.idToken;
        })
    },

    uploadDoc: async (key, file) => {
        try {
            const minioConfig = await MinioService.getMinioConfig();
            console.log("minioConfig", minioConfig);

            const minioClient = new minio.Client({
                endPoint: minioConfig.endPoint,
                port: minioConfig.port,
                useSSL: minioConfig.useSSL,
                accessKey: minioConfig.accessKey,
                secretKey: minioConfig.secretKey,
            });

            const bucketName = minioConfig.bucket;
            console.log("bucketName", bucketName);

            const exists = await minioClient.bucketExists(bucketName);
            if (exists) {
                console.log("Bucket " + bucketName + " exists.");
            } else {
                console.log("Bucket " + bucketName + " does not exist.");
                // await minioClient.makeBucket(bucketName, "us-east-1");
                // console.log("Bucket " + bucketName + " created in us-east-1.");
                return;
            }

            const arrayBuffer = await file.arrayBuffer();
            const buffer = Buffer.from(arrayBuffer);

            const result = await minioClient.putObject(bucketName, key, buffer, file.size, {
                "Content-Type": file.type,
            });

            console.log("result", result);

            // If we get here, the upload was successful
            if (result) {
                return { success: true, etag: result };
            } else {
                throw new Error("Upload completed but no ETag received");
            }
        } catch (error) {
            // More specific error handling
            console.log("error", error);
            
            throw error;
        }
    },
};

export default TestService;
