import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Box from "@mui/material/Box";
import { getMsgFrequencyOptions } from "./MsgFrequencyOption";
import DetailService from "../DetailService";
import { useTranslation } from "react-i18next";

export default function MsgFrequencyChart({ selectedThingId }) {
    const [chartData, setChartData] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            const data = await DetailService.requestMsgFrequency(selectedThingId);
            if (isMounted) {
                // console.log(data);

                if (data && data.code === "no data") {
                    setChartData([]);
                    return;
                }

                if (data && data.length > 0) {
                    setChartData(
                        data.map((r) => ({
                            name: r.timeRange,
                            y: Number(r.percentage.toFixed(2)),
                        }))
                    );
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [selectedThingId]);

    const options = getMsgFrequencyOptions(chartData, t);

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return chartData.length > 0 ? (
        <Box sx={{ m: 2 }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
    ) : (
        <></>
    );
}
