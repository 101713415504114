import ApiService from "Services/apiService";
import fileDownload from "js-file-download";

const BizService = {
    requetCompanies: async (setCompanies) => {
        let requestOptions = {
            url: "/v2/companies",
            params: {
                page: 0,
                pageSize: 100,
                sort: "name:asc",
            },
        };

        // console.log("requetCompanies..");

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);
            setCompanies(r.content);
        });
    },

    requestLastMonth: async (companyId, setLastMonth) => {
        let requestOptions = {
            url: "/v2/usage_history/last_month",
            params: {},
        };

        if (companyId) {
            requestOptions.params["companyId"] = companyId;
        }

        // console.log("requestLastMonth..");
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            if (!r.code) {
                setLastMonth(r);
            } else {
                setLastMonth(null);
            }
        });
    },

    requestReport: async (sort, reportType, language) => {
        let sortStr = sort.map((s) => s.field + ":" + s.sort).join(",");

        let requestOptions = {
            url: `/v2/usage_history/report?page=0&pageSize=100&reportType=${reportType}&sort=${sortStr}&language=${language}`,
        };

        // console.log(requestOptions);

        ApiService.downloadFile(requestOptions)
            .then((response) => {
                fileDownload(response, `MonthlyStatistics.${reportType}`);
            })
            .catch((e) => console.error(e));
    },
};

export default BizService;
