import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsXRange from "highcharts/modules/xrange";

// Initialize modules
HighchartsMore(Highcharts);
HighchartsXRange(Highcharts);

export default function TestXRangeHighchart() {
    const options = {
        chart: {
            type: "xrange",
            height: 400,
        },
        title: {
            text: "Weekly Operating Hours",
        },
        xAxis: {
            type: "datetime",
        },
        yAxis: {
            title: {
                text: "",
            },
            categories: ["Prototyping", "Development"],
            reversed: true,
        },
        accessibility: {
            point: {
                descriptionFormat: "{add index 1}. {yCategory}, {x:%A %e %B %Y} " + "to {x2:%A %e %B %Y}.",
            },
        },
        series: [
            {
                name: "Project 1",
                // pointPadding: 0,
                // groupPadding: 0,
                borderColor: "gray",
                pointWidth: 20,
                data: [
                    {
                        x: Date.UTC(2014, 10, 21), // Month is 0-based, so 10 = November
                        x2: Date.UTC(2014, 11, 2),
                        y: 0,
                        partialFill: 0.25,
                    },
                    {
                        x: Date.UTC(2014, 11, 2),
                        x2: Date.UTC(2014, 11, 5),
                        y: 1,
                    },
                    {
                        x: Date.UTC(2014, 11, 8),
                        x2: Date.UTC(2014, 11, 9),
                        y: 1,
                    },
                    {
                        x: Date.UTC(2014, 11, 9),
                        x2: Date.UTC(2014, 11, 19),
                        y: 1,
                    },
                    {
                        x: Date.UTC(2014, 11, 10),
                        x2: Date.UTC(2014, 11, 23),
                        y: 0,
                    },
                ],
                dataLabels: {
                    enabled: true,
                },
            },
        ],
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
}
