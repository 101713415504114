export const getMsgFrequencyOptions = (chartData, t) => ({
    chart: {
        type: "pie",
        height: "300px",
    },
    title: {
        text: t("de.msg_frequency_title"),
    },
    tooltip: {
        formatter: function () {
            return `<b>${this.point.name}: ${this.point.y}%</b>`;
        },
    },
    series: [
        {
            data: chartData,
        },
    ],
    legend: {
        enabled: true,
    },
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: true,
        buttons: {
            contextButton: {
                symbol: "download",
            },
        },
    },
});
