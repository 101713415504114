import Switch from "@mui/material/Switch";

export const getVisibleSettingColumns = (t, handleVisibilityChange) => [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: t("superviot.station"), flex: 1 },
    {
        field: "visible",
        headerName: t("superviot.visibility"),
        flex: 1,
        renderCell: (params) => <Switch checked={params.value} onChange={() => handleVisibilityChange(params.row.id)} />,
    },
    { field: "product.name", headerName: "Product", flex: 1 },
    { field: "manufacturer.name", headerName: "Manufacturer", flex: 1 },
];
