import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function SiteTypeAutocomplete({ onChange, selectedCode, onSelect }) {
    const [types, setTypes] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        const siteTypes = [
            { label: "Greenhouse", code: "greenhouse" },
            { label: "House", code: "house" },
            { label: "Building", code: "building" },
            { label: "Field", code: "field" },
        ];
        setTypes(siteTypes);
        
        const selected = siteTypes.find((option) => option.code === selectedCode);
        setSelectedValue(selected || null);
    }, [selectedCode]);

    const handleClick = (selectedItem) => {
        // console.log("Selected item:", selectedItem);
        setSelectedValue(selectedItem);
        onSelect(selectedItem.code);
    };

    return (
        <Autocomplete
            options={types}
            value={selectedValue}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Select Type" variant="outlined" />}
            onChange={(event, value) => {
                onChange(value);
                handleClick(value);
            }}
            fullWidth
        />
    );
}
