import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Button from "@mui/material/Button";
import ThresholdDialogV2 from "./ThresholdDialogV2";
import { ChartGeneralOptions } from "./ChartGeneralOptions";
import SensorDetailService from "./SensorDetailService";
import ChartGeneralService from "./ChartGeneralService";
import AddchartIcon from "@mui/icons-material/Addchart";
import { IconButton } from "@mui/material";
import CompareSecondDialog from "./CompareSecondDialog";
import { ATTRIBUTE_BATTERY } from "./CompareSecondDialog";
import Highcharts from "highcharts/highstock";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
highchartsMore(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

export default function ChartGeneral({ thingId, attributeId, parentReload, showThreshold = true }) {
    const { t, i18n } = useTranslation();

    const componentRef = useRef();

    const [selected, setSelected] = useState("spline");
    const [type, setType] = useState("spline");
    const [isOpenThresholdModal, setIsOpenThresholdModal] = useState(false);

    const [name, setName] = useState("Humidity");

    const [thing, setThing] = useState();

    const [openSecond, setOpenSecond] = useState(false);
    const [anotherAttrId, setAnotherAttrId] = useState();

    const [series, setSeries] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (componentRef.current) {
                    componentRef.current.chart.showLoading();
                }
                window.scrollTo(0, 0);

                const thing = await SensorDetailService.requestThing(thingId);
                setThing(thing);

                // First attribute
                const attribute = await SensorDetailService.requestAttribute(thing.product.model.id, attributeId);
                const chartData = await ChartGeneralService.requestHistoryAll(thingId, attributeId, "none");
                const seriesData = [{ attribute, chartData }];

                // Second attribute
                if (anotherAttrId) {
                    const attribute2 = await SensorDetailService.requestAttribute(thing.product.model.id, anotherAttrId);
                    const chartData2 = await ChartGeneralService.requestHistoryAll(thingId, anotherAttrId, "none");
                    seriesData.push({ attribute: attribute2, chartData: chartData2 });
                }

                setSeries(seriesData);

                if (componentRef.current) {
                    componentRef.current.chart.hideLoading();
                }
            } catch (error) {
                console.error(error);
                if (componentRef.current) {
                    componentRef.current.chart.hideLoading();
                }
            }
        };

        fetchData();
    }, [attributeId, thingId, parentReload, showThreshold, anotherAttrId]);

    const handleChartType = (type) => {
        setType(type);
        setSelected(type);
    };

    const handleThreshold = () => {
        setIsOpenThresholdModal(true);
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <Box sx={{ flexGrow: 1, marginTop: 2, width: "100%" }}>
            <Grid container spacing={2}>
                {thing && (
                    <Grid item xs={12}>
                        <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{thing.fixedName}</span>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ToggleButton value="check" selected={selected === "spline" ? true : false} onChange={() => handleChartType("spline")} size="small">
                        <ShowChartIcon />
                    </ToggleButton>
                    <ToggleButton value="check" selected={selected === "column" ? true : false} onChange={() => handleChartType("column")} size="small">
                        <EqualizerIcon />
                    </ToggleButton>

                    {attributeId !== ATTRIBUTE_BATTERY && (
                        <IconButton value="check" size="small" onClick={() => setOpenSecond(true)}>
                            <AddchartIcon />
                        </IconButton>
                    )}

                    {showThreshold && (
                        <Button variant="contained" color="primary" style={{ textTransform: "none", margin: "5px" }} onClick={() => handleThreshold()}>
                            {t("btn.threshold")}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <HighchartsReact ref={componentRef} highcharts={Highcharts} constructorType={"stockChart"} options={ChartGeneralOptions(type, series, t, i18n)} />
                </Grid>
            </Grid>

            <ThresholdDialogV2
                onClose={() => setIsOpenThresholdModal(false)}
                open={isOpenThresholdModal}
                thingId={thingId}
                attributeId={attributeId}
                title="Alerts"
                attributeName={name}
            />

            <CompareSecondDialog
                open={openSecond}
                setOpen={setOpenSecond}
                thing={thing}
                exceptAttributeId={attributeId}
                callback={(id) => {
                    setAnotherAttrId(id);
                }}
                key={attributeId}
            />
        </Box>
    );
}
