import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog, BootstrapDialogTitle } from "Components/ui/BootstrapDialog";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CompanyService from "./CompanyService";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

export default function CompanyDialog({ open, setOpen, selectedCompanyId, selectedCompanyName, callback }) {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [dataRetention, setDataRetention] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleClose = () => {
        setName("");
        setDataRetention(0);
        setLoading(false);
        setError("");
        setOpen(false);
    };

    const handleSubmit = () => {
        if (!name) {
            setError(t("biz.name_error"));
            return;
        }

        setLoading(true);
        setError("");

        const cleanedName = name.trim().replace(/\s+/g, "_").toUpperCase();

        CompanyService.addCompany(cleanedName, dataRetention, selectedCompanyId)
            .then((r) => {
                setLoading(false);
                if (r.code && r.code === "failure") {
                    if (r.message.indexOf("already exists") != -1) {
                        setError(t("biz.already_exists"));
                    } else {
                        setError(t("biz.error"));
                    }
                } else {
                    handleClose();
                }
                callback();
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setError(t("biz.error"));
            });
    };

    useEffect(() => {
        console.log(selectedCompanyId);
        console.log(selectedCompanyName);
    }, [selectedCompanyId, selectedCompanyName]);

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
            <BootstrapDialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {t("biz.add_company")}
            </BootstrapDialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                        {t("biz.name")}
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant="standard"
                            autoFocus
                            fullWidth
                            required
                            disabled={loading}
                            value={name}
                            onChange={(e) => setName(e.target.value.toUpperCase())}
                            helperText={t("biz.name_error")}
                        />
                    </Grid>

                    {selectedCompanyName && (
                        <>
                            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                                Parent company
                            </Grid>
                            <Grid item xs={8}>
                                <TextField variant="standard" fullWidth disabled={true} value={selectedCompanyName} />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                        {t("biz.limit_retention")}:
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant="standard"
                            type="number"
                            fullWidth
                            required
                            disabled={loading}
                            value={dataRetention}
                            onChange={(e) => setDataRetention(parseInt(e.target.value))}
                            InputProps={{
                                endAdornment: <Box sx={{ ml: 1 }}>Days</Box>,
                            }}
                            inputProps={{
                                min: 1,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={{ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" }} onClick={handleSubmit}>
                    {t("btn.add")}
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
