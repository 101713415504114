import ApiService from "Services/apiService";

const VisibilityService = {
    requestThing: async (page, pageSize, sort, setRows, setRowCount, selectedSiteId, appId) => {
        // console.log("requestThing...");
        // console.log(thingId);

        let requestOptions = {
            url: `/v2/things`,
            params: {
                page,
                pageSize,
                keyword: "",
                sort,
            },
        };

        return ApiService.getData(requestOptions)
            .then((r) => {
                if (r.code && r.code.includes("no data")) {
                    setRows([]);
                    setRowCount(0);
                    return;
                }

                const mapped = r.content.map((c) => {
                    // console.log(c);

                    const visibilityField = c.customFields.find((cf) => cf.name === "SUPERVIOT_ICON_VISIBILITY");
                    const visible = visibilityField === undefined ? true : visibilityField.value === "true";

                    return {
                        id: c.id,
                        name: c.fixedName,
                        visible,
                        "product.name": c.product?.name,
                        "manufacturer.name": c.product?.manufacturer?.name,
                    };
                });

                // console.log(mapped)

                setRows(mapped);
                setRowCount(r.totalElements); 
            }) ;
    },

    requestThing2: async (page, pageSize, sort, setRows, setRowCount, selectedSiteId, appId) => {
        // console.log("requestThing...");
        // console.log(thingId);

        let requestOptions = {
            url: `/v2/things`,
            params: {
                page,
                pageSize,
                keyword: "",
                sort,
            },
        };

        return ApiService.getData(requestOptions)
            .then((r) => {
                if (r.code && r.code.includes("no data")) {
                    setRows([]);
                    setRowCount(0);
                    return;
                }

                const mapped = r.content.map((c) => {
                    // console.log(c);

                    // const visibilityField = c.customFields.find((cf) => cf.name === "SUPERVIOT_ICON_VISIBILITY");
                    // const visible = visibilityField === undefined ? true : visibilityField.value === "true";

                    return {
                        id: c.id,
                        name: c.fixedName,
                        // visible,
                        "product.name": c.product?.name,
                        "manufacturer.name": c.product?.manufacturer?.name,
                    };
                });

                // console.log(mapped)

                // setRows(mapped);
                // setRowCount(r.totalElements);

                return mapped;
            })
            .then((mapped) => {
                // console.log(selectedSiteId, appId, mapped);

                if (selectedSiteId && appId) {
                    ApiService.getData({
                        url: `/v2/sites/thing_visibility`,

                        params: {
                            siteId: selectedSiteId,
                            appId,
                        },
                    }).then((r) => {
                        // console.log(r);

                        let remapped = mapped;
                        if (r && r.length > 0) {
                            const visibleThings = r.map((c) => c.thing.id);
                            // console.log(visibleThings);

                            remapped = mapped.map((c) => {
                                return { ...c, visible: visibleThings.some((v) => v === c.id) };
                            });
                        }

                        setRows(remapped);
                        setRowCount(r.totalElements);
                    });
                }
            });
    },

    updateVisibility: async (thingId, visible) => {
        const option = {
            url: `/v2/things/${thingId}/custom_fields`,
            data: [
                {
                    name: "SUPERVIOT_ICON_VISIBILITY",
                    label: "SUPERVIOT_ICON_VISIBILITY",
                    value: visible.toString(),
                    type: "TEXT",
                },
            ],
        };

        // console.log(option);
        return ApiService.putData(option).then((r) => {
            // console.log(r);
            return r;
        });
    },

    updateVisibilityBySite: async (thingId, visibility, selectedSiteId, appId) => {
        const option = {
            url: `/v2/sites/thing_visibility`,
            data: {
                siteId: selectedSiteId,
                thingId,
                visibility,
                appId,
            },
        };
        // console.log(option);

        return ApiService.putData(option).then((r) => {
            // console.log(r);
            return r;
        });
    },

    requestThingVisibility: async (selectedSiteId, appId) => {
        const option = {
            url: `/v2/sites/thing_visibility`,
            params: {
                siteId: selectedSiteId,
                appId,
            },
        };
        // console.log(option);

        return ApiService.getData(option).then((r) => {
            // console.log(r);
            return r;
        });
    },
};

export default VisibilityService;
