import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box } from "@mui/material";
import AppContext from "Components/AppContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import SettingContext from "Providers/SettingContext";
import { useParams } from "react-router-dom";
import ChartGeneral from "../../../components/chart/ChartGeneral";
import SensorDetailService from "../island/chart/SensorDetailService";
import SviChartService from "./SviChartService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps, CustomTabPanel } from "Components/ui/CustomTabPanel";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import AttributeGrid from "../alarm/AttributeGrid";
import LogMain from "../logs/LogMain";
import MaintMain from "../maint/MaintMain";
import SviMiniMap from "./SviMiniMap";
import Alerts from "Components/alerts/Alerts";

export default function SviChartMain() {
    const { t, i18n } = useTranslation();
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);

    const { thingId } = useParams();
    const [thing, setThing] = useState();
    const [attributes, setAttributes] = useState();
    const [value, setValue] = useState(0);
    const [reload, setReload] = useState();

    const [txFreq, setTxFreq] = useState();
    const [pasMesure, setPasMesure] = useState();
    const [siteId, setSiteId] = useState();
    const [siteDesc, setSiteDesc] = useState("");

    useEffect(() => {
        // console.log("uef in SviChartMain");
        // console.log(thingId);

        SensorDetailService.requestThing(thingId).then((t) => {
            // console.log(t)
            setThing(t);

            t.customFields.forEach((c) => {
                if (c.name === "PAS_MESURE") {
                    setPasMesure(c.value);
                } else if (c.name === "TX_FREQ") {
                    setTxFreq(c.value);
                }
            });

            setSiteId(t?.site?.id);
            setSiteDesc(t?.site?.description || "");

            SviChartService.requestAttributes(t, t.product?.model?.id, setAttributes);
        });

        setting.setGlobalSetting({
            appId: AppId.SUPERVIOT,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
        ctx.updateTitle(ApplicationData.find((a) => a.id == AppId.SUPERVIOT).title);
    }, [thingId, reload]);

    const handleChange = (e, newValue) => {
        setValue(newValue);
        setReload(moment().unix());
    };

    const handleDesc = (e) => {
        setSiteDesc(e.target.value);
        debounceDesc(thingId, siteId, e.target.value);
    };

    const debounceDesc = useCallback(
        debounce((thingId, siteId, siteDesc) => {
            // Perform save operation here
            SviChartService.updateSiteDescription(thingId, siteId, siteDesc);
        }, 500),
        []
    );

    return (
        <Box sx={{ pt: 10, ml: 4, mr: 4, display: "flex", flexDirection: "column" }}>
            <Box sx={{ mt: 2 }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {thing && (
                                    <Box sx={{ flexGrow: 1, mt: 0, width: "100%" }}>
                                        <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>
                                            {thing?.site?.name ? `${thing.site.name} - ` : ""}
                                            {thing.fixedName}
                                        </span>
                                        {thing?.site && (
                                            <Typography variant="body1">
                                                Latitude : {thing.site.latitude} - Longitude : {thing.site.longitude}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">
                                    {t("label.transmission")} {txFreq}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">
                                    {t("label.measure")} {pasMesure}
                                </Typography>
                            </Grid>
                            {thing && thing.product && (
                                <Grid item xs={12}>
                                    <Typography variant="body1">{thing.product.name}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12} sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
                                <TextField
                                    label=""
                                    value={siteDesc}
                                    multiline
                                    rows={4}
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: false,
                                    }}
                                    onChange={handleDesc}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{ pl: 1 }}>
                        <SviMiniMap
                            site={thing?.site}
                            callback={() => {
                                setReload(moment());
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
                        <MaintMain thingId={thingId} />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ pt: 2, borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {attributes &&
                        attributes.map((a, idx) => {
                            const name = a.name === "Battery" ? t("attr.battery") : a.name;
                            return <Tab key={idx} label={name} {...a11yProps(idx)} />;
                        })}
                </Tabs>
            </Box>

            <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                {attributes &&
                    attributes.map((a, idx) => {
                        return (
                            <CustomTabPanel value={value} index={idx}>
                                <ChartGeneral thingId={thingId} attributeId={a.id} parentReload={reload} showThreshold={true} />
                            </CustomTabPanel>
                        );
                    })}
            </Box>

            <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                <AttributeGrid key={thingId} siteName={thing?.name} thingId={thingId} defaultTitle />
            </Box>

            <Box sx={{ gap: 2, display: "flex", flexDirection: "column", pt: 6 }}>
                <Alerts thingId={thingId} hideOnOff={true} />
            </Box>

            <Box sx={{ gap: 2, display: "flex", flexDirection: "column", pt: 6 }}>
                <LogMain defaultTitle thingId={thingId} />
            </Box>
        </Box>
    );
}
