import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CustomStyle from "Components/CustomStyle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SiteDialogue from "Components/site/SiteDialogue";
import moment from "moment";
import Grid from "@mui/material/Grid";
import DetailService from "./DetailService";

export default function SiteSelector({ callback }) {
    let defaltError = {
        size: false,
        radio: false,
        firstname: false,
        lastname: false,
        location: false,

        unit: false,
        email: false,
        message: "",
    };
    const [errors, setError] = useState(defaltError);
    const [location, setLocation] = useState("");
    const [openSite, setOpenSite] = useState(false);
    const [reload, setReload] = useState();

    const [isSaving, setIsSaving] = useState(false);
    const [selectedSiteId, setSelectedSiteId] = useState();
    const [site, setSite] = useState();

    useEffect(() => {
        if (selectedSiteId) {
            DetailService.requestSite(selectedSiteId).then((site) => {
                setSite(site);
            });
        }
    }, [isSaving, reload, selectedSiteId]);

    const handleValue = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "txtLocation":
                setLocation(value);
                break;
            default:
                break;
        }
    };

    return (
        <Box sx={{ width: 400 }}>
            {/* <Paper elevation={3} sx={{ p: 1 }}> */}
            <Grid container spacing={0} sx={{ alignItems: "center" }}>
                <Grid item xs={3} sx={{ pl: 2 }}>
                    <Typography>Sites</Typography>
                </Grid>
                <Grid item xs={9} sx={{ display: "flex" }}>
                    <TextField
                        required
                        name="txtLocation"
                        fullWidth
                        margin="dense"
                        size="small"
                        value={site?.name}
                        onChange={(e) => handleValue(e)}
                        error={errors.location ? true : false}
                        sx={CustomStyle.RemoveLabel}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <IconButton aria-label="delete" size="large" variant="contained" onClick={() => setOpenSite(true)}>
                        <MoreHorizIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
            </Grid>
            {/* </Paper> */}

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                selectedThingId={undefined}
                setReloadParent={(siteId) => {
                    // console.log(siteId);
                    setSelectedSiteId(siteId);
                    setReload(moment());
                    callback(siteId);
                }}
            />
        </Box>
    );
}
