import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Item from "Components/ui/Item";
import { CustomButton } from "Components/ui/CustomButton";
import Stack from "@mui/material/Stack";
import GraphService from "./GraphService";
import moment from "moment";
import UtilService from "Services/utilService";
import { ChartOption } from "./ChartOption";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import { useTheme } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { useTranslation } from "react-i18next";
import ThingService from "Services/thing/ThingService";
HighchartsExporting(Highcharts);

export default function GraphMain({ selectedThingId }) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(new Date());
    const [options, setOptions] = useState({
        title: { text: null },
        subtitle: { text: null },
        credits: { enabled: false },
    });

    const [type, setType] = useState("column");
    const [attributeName, setAttributeName] = useState("volume");
    const [interval, setInterval] = useState("day");

    const theme = useTheme();
    const [screenSize, setScreenSize] = useState("md");
    const [thing, setThing] = useState();
    const [product, setProduct] = useState();

    useEffect(() => {
        // console.log("uef in GraphMain");
        // console.log(selectedThingId)
        // console.log(user);

        ThingService.requestThing(selectedThingId).then((t) => {
            setThing(t);
            setProduct(t.product);


            GraphService.requestHistoryAverage(selectedThingId, interval, startDate, t.product).then((data) => {
                let categories = [];

                if (interval === "day") {
                    let sDate = moment(startDate).startOf("day");
                    for (let i = 0; i < 24; i++) {
                        categories.push(UtilService.formateH(sDate.valueOf()));
                        sDate.add(1, "hour");
                    }
                } else if (interval === "week") {
                    let sDate = moment(startDate).startOf("week");
                    for (let i = 0; i < 7; i++) {
                        categories.push(UtilService.formatDo(sDate.valueOf()));
                        sDate.add(1, "day");
                    }
                } else if (interval === "month") {
                    let sDate = moment(startDate).startOf("month");
                    const sDateMonth = sDate.format("MM");
                    for (let i = 0; i < 31; i++) {
                        categories.push(UtilService.formatDo(sDate.valueOf()));
                        sDate.add(1, "day");

                        if (sDate.format("MM") != sDateMonth) {
                            break;
                        }
                    }
                } else if (interval === "year") {
                    let sDate = moment(startDate).startOf("year");
                    const sDateYear = sDate.format("YYYY");

                    for (let i = 0; i < 12; i++) {
                        categories.push(UtilService.formatM(sDate.valueOf()));
                        sDate.add(1, "month");

                        if (sDate.format("YYYY") != sDateYear) {
                            break;
                        }
                    }
                }

                const chartData = [];

                categories.forEach((cat) => {
                    let value = 0;

                    if (data && data.filter((d) => d[0] == cat).length > 0) {
                        const entity = data.filter((d) => d[0] == cat)[0];
                        value = entity[1];
                    }

                    // chartData.push([value, unit, timestamp]);
                    chartData.push(value);
                });

                // console.log(product.id);

                let unit = "A";

                if (interval == "week") {
                    const weekAgo = moment(startDate).subtract(1, "week").startOf("week");

                    // Get last week's data
                    GraphService.requestHistoryAverage(selectedThingId, interval, weekAgo, product).then((weekAgo) => {
                        // console.log("weekAgo...........")
                        // console.log(weekAgo)
                        setOptions(ChartOption(attributeName, type, unit, chartData, interval, categories, data, weekAgo));
                    });
                } else if (interval == "day") {
                    setOptions(ChartOption(attributeName, type, unit, chartData, interval, categories, data, []));
                } else {
                    setOptions(ChartOption(attributeName, type, unit, chartData, interval, categories, data, []));
                }
            });
        });

        const handleResize = () => {
            const breakpoints = theme.breakpoints;
            let currentSize = "xs";

            if (window.innerWidth >= breakpoints.values.sm) {
                currentSize = "sm";
            }
            if (window.innerWidth >= breakpoints.values.md) {
                currentSize = "md";
            }
            if (window.innerWidth >= breakpoints.values.lg) {
                currentSize = "lg";
            }
            if (window.innerWidth >= breakpoints.values.xl) {
                currentSize = "xl";
            }
            setScreenSize(currentSize);
        };
        handleResize();
    }, [interval, startDate, theme.breakpoints]);

    const handleButtons = (interval) => {
        // console.log("handleButtons....." + interval);
        setInterval(interval);
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12}>
                    <Box sx={{ flexGrow: 1, mt: 4, width: "100%" }}>
                        <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{t("emera_v2.consumption")}</span>
                    </Box>
                </Grid>
                <Grid item md={4} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date"
                                value={startDate}
                                onChange={(newValue) => {
                                    // console.log(newValue);
                                    setStartDate(newValue);
                                }}
                                showTodayButton
                                maxDate={moment()}
                                slotProps={{ textField: { size: "small" } }}
                            />
                        </LocalizationProvider>
                    </Item>
                </Grid>
                <Grid item md={8} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: screenSize == "xs" ? "center" : "left", width: "100%" }}>
                    <Stack spacing={1} direction={"row"}>
                        <CustomButton name="Hour" callback={() => handleButtons("day")} toggled={interval == "day" ? true : false} />
                        {/* <CustomButton name="Week" callback={() => handleButtons("week")} toggled={interval == "week" ? true : false} /> */}
                        <CustomButton name="Day" callback={() => handleButtons("month")} toggled={interval == "month" ? true : false} />
                        <CustomButton name="Month" callback={() => handleButtons("year")} toggled={interval == "year" ? true : false} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {/* <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={options} /> */}
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </Grid>
            </Grid>
        </Box>
    );
}
