import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

function MapClickHandler({ callback, iconPosition, setIconPosition }) {
    const map = useMap();
    
    React.useEffect(() => {
        if (!map) return;

        const handleClick = (e) => {
            const { lat, lng } = e.latlng;
            // console.log(`Clicked position: [${lat}, ${lng}]`);
            callback([lat, lng]);
            setIconPosition([lat, lng]);
        };

        map.on('click', handleClick);

        // Change cursor style
        map.getContainer().style.cursor = 'pointer';

        return () => {
            map.off('click', handleClick);
            // Reset cursor style when component unmounts
            map.getContainer().style.cursor = '';
        };
    }, [map, callback, setIconPosition]);

    return null;
}

function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

export default function MiniMap({ zoom = 13, callback, selectedSite }) {
    const [iconPosition, setIconPosition] = useState(undefined);
    const [center, setCenter] = useState([45.5017, -73.5673]);
    const mapRef = useRef(null);

    useEffect(() => {
        // console.log("uef in MiniMap", selectedSite);

        if (selectedSite && selectedSite.latitude && selectedSite.longitude) {
            const newCenter = [parseFloat(selectedSite.latitude), parseFloat(selectedSite.longitude)];
            setIconPosition(newCenter);
            setCenter(newCenter);
            // console.log("uef in setCenter", newCenter);
        } else {
            setIconPosition(undefined);
        }
    }, [selectedSite]);

    return (
        <div style={{ height: '200px', width: '100%', marginTop: '20px' }}>
            <MapContainer 
                center={center} 
                zoom={zoom} 
                style={{ height: '100%', width: '100%' }}
                ref={mapRef}
            >
                <ChangeView center={center} zoom={zoom} />
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <MapClickHandler callback={callback} iconPosition={iconPosition} setIconPosition={setIconPosition} />
                {iconPosition && (
                    <Marker 
                        position={iconPosition} 
                        icon={DefaultIcon}
                        draggable={false}
                        eventHandlers={{
                            dragend: (e) => {
                                const newPosition = e.target.getLatLng();
                                // console.log(`Marker moved to: [${newPosition.lat}, ${newPosition.lng}]`);
                                callback([newPosition.lat, newPosition.lng]);
                                setIconPosition([newPosition.lat, newPosition.lng]);
                            },
                        }}
                    />
                )}
            </MapContainer>
        </div>
    );
}
