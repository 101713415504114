import React from "react";
import { Box } from "@mui/material";
import ChartGeneral from "Components/chart/ChartGeneral";
import { useParams } from "react-router-dom";
import GraphMain from "./consumption/GraphMain";
import { useTranslation } from "react-i18next";

export default function ChartGeneralMain() {
    const { t } = useTranslation();
    const { thingId, attributeId } = useParams();

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 10, ml: 4, mr: 4 }}>
            <ChartGeneral thingId={thingId} attributeId={attributeId} />
            <GraphMain selectedThingId={thingId} />
        </Box>
    );
}
