import React, { useState, useEffect, useRef } from "react";
import { Text, Image as KonvaImage, Rect, Group } from "react-konva";
import SmartService from "./SmartService";
import ImgBlue from "Assets/icons/group-blue.png";
import ImgGreen from "Assets/icons/group-green.png";
import ImgOrange from "Assets/icons/group-orange.png";
import ImgRed from "Assets/icons/group-red.png";
import ImgYellow from "Assets/icons/group-yellow.png";
import useImage from "use-image";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function SmartKonvaImage({ positions, callback }) {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        isDragging: false,

        x: 0,
        y: 0,
    });
    const [visibleIcon, setVisibleIcon] = useState(false);

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipX, setTooltipX] = useState(0);
    const [tooltipY, setTooltipY] = useState(0);
    const [tooltipText, setTooltipText] = useState();
    const [worstColor, setWorstColor] = useState("BLUE"); // Default worstColor to 'BLUE'

    const [tooltipHeight, setTooltipHeight] = useState(0);
    const [tooltipWidth, setTooltipWidth] = useState(200);

    const tooltipRef = useRef();
    const imageRef = useRef();

    // Load all images
    const [imgBlue] = useImage(ImgBlue);
    const [imgGreen] = useImage(ImgGreen);
    const [imgOrange] = useImage(ImgOrange);
    const [imgRed] = useImage(ImgRed);
    const [imgYellow] = useImage(ImgYellow);

    // Map worstColor to corresponding image
    const imageMap = {
        BLUE: imgBlue,
        GREEN: imgGreen,
        ORANGE: imgOrange,
        RED: imgRed,
        YELLOW: imgYellow,
    };

    const [visibilityId, setVisibilityId] = useState();

    useEffect(() => {
        // console.log("positions", positions);
        if (positions) {
            setVisibilityId(positions.id);
            if (positions.position) {
                const p = JSON.parse(positions.position);

                setState({
                    x: p.x,
                    y: p.y,
                });
                setTooltipX(p.x);
                setTooltipY(p.y);
            } else {
                setState({
                    x: 0,

                    y: 0,
                });
                setTooltipX(0);
                setTooltipY(0);
            }

            setVisibleIcon(true);
        } 
    }, []);

    useEffect(() => {
        // Update Konva image when worstColor changes
        const imageNode = imageRef.current;
        if (imageNode) {
            imageNode.image(imageMap[worstColor]);
            imageNode.getLayer().batchDraw(); // Redraw the layer to reflect the image change
        }
    }, [worstColor, imageMap]);

    useEffect(() => {
        if (tooltipRef.current) {
            tooltipRef.current.zIndex(100); // Set a high zIndex value to bring it to the top
        }
    }, [tooltipVisible]);

    const handleClick = () => {
        // console.log("positions", positions);
        callback(positions);
    };

    const handleIconPosition = (x, y) => {
        SmartService.saveIconPositionBySite(visibilityId, x, y);
    };

    const dragBoundFunc = (pos) => {
        const stage = imageRef.current.getStage();
        const stageWidth = stage.width();
        const stageHeight = stage.height();
        const imageWidth = imageRef.current.width();
        const imageHeight = imageRef.current.height();

        return {
            x: Math.min(Math.max(pos.x, 0), stageWidth - imageWidth),
            y: Math.min(Math.max(pos.y, 0), stageHeight - imageHeight),
        };
    };

    return (
        <>
            <KonvaImage
                ref={imageRef} // Reference to the Konva image node
                image={imageMap[worstColor]} // Default image based on worstColor
                x={state.x}
                y={state.y}
                width={50}
                height={50}
                draggable
                dragBoundFunc={dragBoundFunc}
                onDragStart={() => setState({ isDragging: true })}
                onDragEnd={(e) => {
                    setState({
                        isDragging: false,
                        x: e.target.x(),
                        y: e.target.y(),
                    });

                    setTooltipX(e.target.x());
                    setTooltipY(e.target.y());

                    handleIconPosition(e.target.x(), e.target.y());
                }}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseOut={() => setTooltipVisible(false)}
                onClick={handleClick}
                visible={visibleIcon}
            />
            <Group x={tooltipX - 23} y={tooltipY + 60} visible={visibleIcon}>
                <Rect fill="black" opacity={0.75} cornerRadius={5} width={100} height={25} />
                <Text text={positions.name} fill="white" padding={5} fontSize={14} align="center" width={100} />
            </Group>

            {tooltipVisible && (
                <Group ref={tooltipRef} x={tooltipX + 60} y={tooltipY}>
                    <Rect fill="black" opacity={0.75} cornerRadius={5} width={tooltipWidth} height={tooltipHeight} />
                    {tooltipText &&
                        tooltipText.map((t, i) => (
                            <Group key={i}>
                                <Text text={t[0]} fill="white" padding={5} fontSize={14} align="left" y={i * 16} x={5} width={tooltipWidth} />
                                <Text text={t[1]} fill="white" padding={5} fontSize={14} align="right" y={i * 16} width={tooltipWidth} />
                            </Group>
                        ))}
                </Group>
            )}
        </>
    );
}
