import React, { useState, useEffect, useContext } from "react";
import { Stage, Layer, Image } from "react-konva";
import Box from "@mui/material/Box";
import SmartKonvaImage from "./SmartKonvaImage";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import ImageService from "Services/imageService";
import { useTranslation } from "react-i18next";
import ImportTool from "../deviceExplorer/toolBar/ImportTool";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CreateIcon from "@mui/icons-material/Create";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CustomStyledButton from "Components/ui/CustomStyledButton";
import Tooltip from "@mui/material/Tooltip";
import VisiblityMain from "Components/visibility/VisiblityMain";
import UserService from "Services/userService";
import { AppId } from "Components/applications/ApplicationData";
import DashboardService from "./table/DashboardService";
import SiteSelector from "./site/SiteSelector";
import dayjs from "dayjs";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import VisibilityService from "Components/visibility/VisibilityService";
import SideManu from "Routes/superviot/island/sidebar/SideManu";

export default function SmartAgriculture() {
    const ctx = useContext(AppContext);
    ctx.updateTitle("Smart Agriculture");
    const setting = useContext(SettingContext);

    const { t, i18n } = useTranslation();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [reload, setReload] = useState();

    const code = AppId.SMART_AGRICULTURE + "_BG_IMAGE";
    const [backgroundImage, setBackgroundImage] = useState();
    const [openImport, setOpenImport] = useState(false);
    const [scale, setScale] = useState(1);
    const [openVisibleSetting, setOpenVisibleSetting] = useState(false);
    const [user, setUser] = useState();

    const [positions, setPositions] = useState();
    const [alertCount, setAlertCount] = useState([]);

    const [selectedSiteId, setSelectedSiteId] = useState();
    const [selectedPosition, setSelectedPosition] = useState();

    useEffect(() => {
        // console.log("useEffect in SmartAgriculture");
        setting.setGlobalSetting({
            appId: AppId.SMART_AGRICULTURE,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });

        // if (!backgroundImage) {
        ImageService.requestBackgroundImage(code, selectedSiteId).then((r) => {
            // console.log(r);
            if (r) {
                const bgImg = new window.Image();

                bgImg.src = "data:image/png;base64," + r;
                setBackgroundImage(bgImg);

                bgImg.onload = () => {
                    setWidth(bgImg.naturalWidth);
                    setHeight(bgImg.naturalHeight);
                };

                DashboardService.requestAlertCountByCluster(setAlertCount);
            } else {
                setBackgroundImage(null);
            }
            setPositions([]);

            if (selectedSiteId) {
                VisibilityService.requestThingVisibility(selectedSiteId, AppId.SMART_AGRICULTURE).then((r) => {
                    // console.log(r);

                    if (r) {
                        const visibleThings = r.map((c) => ({
                            id: c.id,
                            name: c.thing.name,
                            fixedName: c.thing.fixedName,
                            thingId: c.thing.id,
                            position: c.position,
                        }));
                        // console.log(visibleThings);

                        setPositions(visibleThings);
                    }
                });
            }
        });

        UserService.getAuthenticatedUser().then((r) => {
            setUser(r);
        });
    }, [code, selectedSiteId, reload]);

    const handleImage = (e) => {
        const auth = process.env.REACT_APP_AUTH_TYPE;

        ImageService.uploadBackgroundImage(code, e.target.files[0], auth, selectedSiteId).then((r) => {
            // console.log("response", r);
            setBackgroundImage(null);
            setReload(dayjs());
        });
    };

    const handleCallback = (p) => {
        // console.log("handleCallback");
        setSelectedPosition(p);
        setReload(dayjs());
    };


    const zoomIn = () => {
        setScale((prevScale) => Math.min(prevScale * 1.2, 5));
    };

    const zoomOut = () => {
        setScale((prevScale) => Math.max(prevScale / 1.2, 0.2));
    };

    return (
        <Box sx={{ flexGrow: 1, pt: 0, display: "flex", justifyContent: "center", mt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={2} sx={{ alignItems: "center", wrap: "nowrap" }}>
                <Grid item xs={12}>
                    <SiteSelector callback={(siteId) => setSelectedSiteId(siteId)} />
                </Grid>

                {selectedSiteId && (
                    <>
                        <Grid item xs={12} sx={{ gap: 1, display: "flex", flexDirection: "row" }}>
                            <CustomStyledButton icon={<ZoomInIcon />} callback={zoomIn} />

                            <CustomStyledButton icon={<ZoomOutIcon />} callback={zoomOut} />

                            {user && user.authorities !== "USER" && (
                                <>
                                    <Tooltip title="Image">
                                        <label htmlFor="contained-button-file">
                                            <input style={{ display: "none" }} accept="image/*" id="contained-button-file" type="file" onChange={handleImage} />
                                            <CustomStyledButton component="span" icon={<AddPhotoAlternateIcon />} />
                                        </label>
                                    </Tooltip>

                                    <Tooltip title={t("superviot.add")}>
                                        <label>
                                            <CustomStyledButton icon={<CreateIcon />} callback={() => setOpenVisibleSetting(true)} />
                                        </label>
                                    </Tooltip>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "flex-start", height: "100%" }}>
                            <Stage width={width} height={height} scaleX={scale} scaleY={scale} draggable>
                                <Layer>{backgroundImage && <Image image={backgroundImage} />}</Layer>

                                <Layer>
                                    {positions &&
                                        positions.map((p) => {
                                            return <SmartKonvaImage positions={p} key={uuidv4()} callback={(p) => handleCallback(p)} />;
                                        })}
                                </Layer>
                            </Stage>{" "}
                        </Grid>
                    </>
                )}
            </Grid>
            <ImportTool openImport={openImport} setOpenImport={setOpenImport} />

            <VisiblityMain
                open={openVisibleSetting}
                onClose={() => setOpenVisibleSetting(false)}
                setReloadParent={() => setReload(dayjs())}
                visibleStation={false}
                selectedSiteId={selectedSiteId}
                appId={AppId.SMART_AGRICULTURE}
            />

            <SideManu position={selectedPosition} hideOptions={true} />
        </Box>
    );
}
